<template>
  <v-menu offset-y offset-x content-class="share--popup-container">
    <template v-slot:activator="{ on, attrs }">
      <img
        class="share--icon"
        src="@/assets/img/icons/share.svg"
        alt="Bagikan"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <vue-goodshare-facebook
      class="popup--item share--fb"
      button_design=""
      :page_url="socialUrl"
      has_icon
    ></vue-goodshare-facebook>
    <vue-goodshare-twitter
      class="popup--item share--tw"
      button_design=""
      :page_url="socialUrl"
      has_icon
    ></vue-goodshare-twitter>
    <vue-goodshare-whatsapp
      class="popup--item share--wa"
      button_design=""
      :page_url="socialUrl"
      has_icon
    ></vue-goodshare-whatsapp>
  </v-menu>
</template>

<script>
const VueGoodshareFacebook = () => import('vue-goodshare/src/providers/Facebook.vue');
const VueGoodshareTwitter = () => import('vue-goodshare/src/providers/Twitter.vue');
const VueGoodshareWhatsapp = () => import('vue-goodshare/src/providers/WhatsApp.vue');
export default {
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    VueGoodshareWhatsapp,
  },
  computed: {
    socialUrl() {
      return this.$store.state.global.baseUrl + this.$route.fullPath;
    },
  },
};
</script>
